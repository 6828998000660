var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', {
    attrs: {
      "no-body": ""
    }
  }, [_c('b-card-body', [_c('b-card-title', [_vm._v(" Mata Pelajaran ")]), _c('b-card-text', [_c('b-form', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Pilih Mata Pelajaran ")]), _c('v-select', {
    staticClass: "select-size-sm w-full",
    attrs: {
      "label": "text",
      "clearable": true,
      "options": _vm.optujian
    },
    model: {
      value: _vm.formUjian.ujian_id,
      callback: function callback($$v) {
        _vm.$set(_vm.formUjian, "ujian_id", $$v);
      },
      expression: "formUjian.ujian_id"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Waktu (Menit)")]), _c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.formUjian.timer,
      callback: function callback($$v) {
        _vm.$set(_vm.formUjian, "timer", $$v);
      },
      expression: "formUjian.timer"
    }
  })], 1), _vm.jadwalujianDataId.is_date_in_subject == 1 ? _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Tanggal Mulai")]), _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      }
    },
    model: {
      value: _vm.formUjian.start_time,
      callback: function callback($$v) {
        _vm.$set(_vm.formUjian, "start_time", $$v);
      },
      expression: "formUjian.start_time"
    }
  })], 1) : _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Tanggal Mulai "), _c('i', {
    staticClass: "text-primary"
  }, [_vm._v("*Tanggal Awal Disamakan dengan jadwal")])]), _c('flat-pickr', {
    staticClass: "form-control disabled",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      },
      "disabled": ""
    },
    model: {
      value: _vm.formUjian.start_time,
      callback: function callback($$v) {
        _vm.$set(_vm.formUjian, "start_time", $$v);
      },
      expression: "formUjian.start_time"
    }
  })], 1), _vm.jadwalujianDataId.is_date_in_subject == 1 ? _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Tanggal Akhir")]), _c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      }
    },
    model: {
      value: _vm.formUjian.end_time,
      callback: function callback($$v) {
        _vm.$set(_vm.formUjian, "end_time", $$v);
      },
      expression: "formUjian.end_time"
    }
  })], 1) : _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Tanggal Akhir "), _c('i', {
    staticClass: "text-primary"
  }, [_vm._v("*Tanggal Akhir Disamakan dengan jadwal")])]), _c('flat-pickr', {
    staticClass: "form-control disabled",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      },
      "disabled": ""
    },
    model: {
      value: _vm.formUjian.end_time,
      callback: function callback($$v) {
        _vm.$set(_vm.formUjian, "end_time", $$v);
      },
      expression: "formUjian.end_time"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "sm": "6"
    }
  }, [_c('label', [_vm._v("Passing Grade")]), _c('b-form-input', {
    attrs: {
      "type": "number"
    },
    model: {
      value: _vm.formUjian.passing_grade,
      callback: function callback($$v) {
        _vm.$set(_vm.formUjian, "passing_grade", $$v);
      },
      expression: "formUjian.passing_grade"
    }
  })], 1)], 1)], 1)], 1), _c('b-button', {
    staticClass: "mb-1 float-right",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.Tambahujian($event);
      }
    }
  }, [_vm._v(" Simpan Ujian ")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "sm",
      "per-page": _vm.perPage2,
      "current-page": _vm.currentPage2,
      "sort-by": _vm.sortBy2,
      "sort-desc": _vm.sortDesc2,
      "sort-direction": _vm.sortDirection2,
      "filter": _vm.filter2,
      "filter-included-fields": _vm.filterOn2,
      "fields": _vm.fields2,
      "items": _vm.dataUjian
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy2 = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc2 = $event;
      },
      "filtered": _vm.onFiltered2
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
      }
    }, {
      key: "cell(start_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDateTime(data.item.start_time)) + " ")];
      }
    }, {
      key: "cell(end_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDateTime(data.item.end_time)) + " ")];
      }
    }, {
      key: "cell(timer)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.timer) + " ")];
      }
    }, {
      key: "cell(passing_grade)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.passing_grade) + " ")];
      }
    }, {
      key: "cell(max_quota)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(data.item.max_quota)) + " ")];
      }
    }, {
      key: "cell(action2)",
      fn: function fn(row) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbah(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows2,
      "per-page": _vm.perPage2,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage2,
      callback: function callback($$v) {
        _vm.currentPage2 = $$v;
      },
      expression: "currentPage2"
    }
  })], 1)], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', [_c('b-table', {
    attrs: {
      "small": "",
      "responsive": "sm",
      "per-page": _vm.perPage,
      "current-page": _vm.currentPage,
      "sort-by": _vm.sortBy,
      "sort-desc": _vm.sortDesc,
      "sort-direction": _vm.sortDirection,
      "filter": _vm.filter,
      "filter-included-fields": _vm.filterOn,
      "fields": _vm.fields,
      "items": _vm.dataUjian
    },
    on: {
      "update:sortBy": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sort-by": function updateSortBy($event) {
        _vm.sortBy = $event;
      },
      "update:sortDesc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "update:sort-desc": function updateSortDesc($event) {
        _vm.sortDesc = $event;
      },
      "filtered": _vm.onFiltered
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
      }
    }, {
      key: "cell(start_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDateTime(data.item.start_time)) + " ")];
      }
    }, {
      key: "cell(end_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDateTime(data.item.end_time)) + " ")];
      }
    }, {
      key: "cell(timer)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.timer) + " ")];
      }
    }, {
      key: "cell(passing_grade)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.passing_grade) + " ")];
      }
    }, {
      key: "cell(max_quota)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.formatRupiah(data.item.max_quota)) + " ")];
      }
    }, {
      key: "cell(action2)",
      fn: function fn(row) {
        return [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalUbah(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "Edit2Icon"
          }
        }), _c('span', [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
          on: {
            "click": function click($event) {
              return _vm.ModalHapus(row.item);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "TrashIcon"
          }
        }), _c('span', [_vm._v("Hapus")])], 1)], 1)];
      }
    }])
  }), _c('b-pagination', {
    staticClass: "my-0",
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "align": "center",
      "size": "sm"
    },
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }