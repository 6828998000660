var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12"
    }
  }, [_c('b-tabs', [_c('b-tab', {
    attrs: {
      "active": ""
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "ArchiveIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Detail")])];
      },
      proxy: true
    }])
  }, [_c('b-modal', {
    attrs: {
      "id": "modal-tambah",
      "centered": "",
      "size": "sm",
      "title": _vm.titleModal
    },
    scopedSlots: _vm._u([{
      key: "modal-footer",
      fn: function fn() {
        return [_c('div', {
          staticClass: "w-100"
        }, [_c('p', {
          staticClass: "float-left mb-0"
        }), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "mr-1 btn-icon",
          attrs: {
            "type": "submit",
            "variant": "outline-success"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.Tambah($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "SaveIcon"
          }
        }), _vm._v(_vm._s(_vm.label || "Tambah") + " ")], 1), _c('b-button', {
          directives: [{
            name: "ripple",
            rawName: "v-ripple.400",
            value: 'rgba(255, 255, 255, 0.15)',
            expression: "'rgba(255, 255, 255, 0.15)'",
            modifiers: {
              "400": true
            }
          }],
          staticClass: "float-right btn-icon",
          attrs: {
            "variant": "outline-primary"
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.tutupModal($event);
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-25",
          attrs: {
            "icon": "LogOutIcon"
          }
        }), _vm._v("Tutup ")], 1)], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.Modal,
      callback: function callback($$v) {
        _vm.Modal = $$v;
      },
      expression: "Modal"
    }
  }, [_c('b-card-text', [_c('b-form', [_c('b-form-group', {
    attrs: {
      "label": "Paket",
      "label-for": "paket"
    }
  }, [_c('b-form-select', {
    attrs: {
      "options": _vm.optpaket
    },
    model: {
      value: _vm.jadwalujianDataId.paket_id,
      callback: function callback($$v) {
        _vm.$set(_vm.jadwalujianDataId, "paket_id", $$v);
      },
      expression: "jadwalujianDataId.paket_id"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Nama Jadwal",
      "label-for": "name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "name",
      "placeholder": "name"
    },
    model: {
      value: _vm.jadwalujianDataId.name,
      callback: function callback($$v) {
        _vm.$set(_vm.jadwalujianDataId, "name", $$v);
      },
      expression: "jadwalujianDataId.name"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tanggal & Waktu Mulai",
      "label-for": "start_time"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      }
    },
    model: {
      value: _vm.jadwalujianDataId.start_time,
      callback: function callback($$v) {
        _vm.$set(_vm.jadwalujianDataId, "start_time", $$v);
      },
      expression: "jadwalujianDataId.start_time"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Tanggal & Waktu Akhir",
      "label-for": "end_time"
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "config": {
        enableTime: true,
        dateFormat: 'Y-m-d H:i:S',
        time_24hr: true
      }
    },
    model: {
      value: _vm.jadwalujianDataId.end_time,
      callback: function callback($$v) {
        _vm.$set(_vm.jadwalujianDataId, "end_time", $$v);
      },
      expression: "jadwalujianDataId.end_time"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Timer",
      "label-for": "timer"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "timer",
      "placeholder": "timer"
    },
    model: {
      value: _vm.jadwalujianDataId.timer,
      callback: function callback($$v) {
        _vm.$set(_vm.jadwalujianDataId, "timer", $$v);
      },
      expression: "jadwalujianDataId.timer"
    }
  })], 1), _c('b-form-group', {
    attrs: {
      "label": "Minimal Nilai",
      "label-for": "min_nilai"
    }
  }, [_c('b-form-input', {
    attrs: {
      "id": "min_nilai",
      "placeholder": "min_nilai"
    },
    model: {
      value: _vm.jadwalujianDataId.min_nilai,
      callback: function callback($$v) {
        _vm.$set(_vm.jadwalujianDataId, "min_nilai", $$v);
      },
      expression: "jadwalujianDataId.min_nilai"
    }
  })], 1)], 1)], 1)], 1), _c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "cols": "12",
      "xl": "12"
    }
  }, [_c('table', {
    staticClass: "mt-xl-0 w-100"
  }, [_c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "StarIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Nama Jadwal")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" : " + _vm._s(_vm.jadwalujianDataId.name ? _vm.jadwalujianDataId.name : "") + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Tanggal & Waktu Mulai")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" : " + _vm._s(_vm.jadwalujianDataId.is_date_in_subject == 1 ? "Di masing-masing ujian" : _vm.humanDateTime(_vm.jadwalujianDataId.start_time)) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CalendarIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Tanggal & Waktu Akhir")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" : " + _vm._s(_vm.jadwalujianDataId.is_date_in_subject == 1 ? "Di masing-masing ujian" : _vm.humanDateTime(_vm.jadwalujianDataId.end_time)) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UsersIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Peserta (Total/Maks Kuota)")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" : " + _vm._s(_vm.jadwalujianDataId.sum_member + "/" + _vm.jadwalujianDataId.max_quota) + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "UsersIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Tipe")])], 1), _c('td', {
    staticClass: "pb-50"
  }, [_vm._v(" : " + _vm._s(_vm.jadwalujianDataId.type == "member" ? "Member" : "Non Member") + " ")])]), _c('tr', [_c('th', {
    staticClass: "pb-50"
  }, [_c('feather-icon', {
    staticClass: "mr-75",
    attrs: {
      "icon": "CheckIcon"
    }
  }), _c('span', {
    staticClass: "font-weight-bold"
  }, [_vm._v("Kategori Paket")])], 1), _c('td', {
    staticClass: "pb-50 text-capitalize"
  }, [_vm._v(" : " + _vm._s(_vm.jadwalujianDataId.paket ? _vm.jadwalujianDataId.paket.name : "-") + " ")])])])]), _c('hr')], 1)], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "CheckCircleIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Daftar Ujian")])];
      },
      proxy: true
    }])
  }, [_c('b-card', [_c('b-table', {
    attrs: {
      "striped": "",
      "hover": "",
      "responsive": "",
      "fields": _vm.jadwalujianDataId.is_date_in_subject == false || _vm.jadwalujianDataId.is_date_in_subject == 0 ? _vm.fields : _vm.fields2,
      "items": _vm.dataujian
    },
    scopedSlots: _vm._u([{
      key: "cell(index)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.index + 1) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function fn(row) {
        var _vm$$route$params;

        return [_c('div', [_c('b-dropdown', {
          attrs: {
            "variant": "link",
            "toggle-class": "text-decoration-none",
            "no-caret": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function fn() {
              return [_c('feather-icon', {
                staticClass: "text-body align-middle mr-25",
                style: _vm.dataujian.length == 1 ? {
                  height: '100px'
                } : '',
                attrs: {
                  "icon": "MoreVerticalIcon",
                  "size": "16"
                }
              })];
            },
            proxy: true
          }], null, true)
        }, [_c('b-dropdown-item', {
          attrs: {
            "to": {
              name: 'admin-jadwal-detail-soal',
              query: {
                ujian_id: row.item.ujian_id,
                category_paket_id: _vm.jadwalujianDataId.paket.category_id,
                active_tab: 'soal',
                j: (_vm$$route$params = _vm.$route.params) === null || _vm$$route$params === void 0 ? void 0 : _vm$$route$params.id
              }
            }
          }
        }, [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "PlusIcon"
          }
        }), _c('span', [_vm._v("Detail Ujian & Buat Soal")])], 1)], 1)], 1)];
      }
    }, {
      key: "cell(start_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDateTime(data.item.start_time)) + " ")];
      }
    }, {
      key: "cell(end_time)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.humanDateTime(data.item.end_time)) + " ")];
      }
    }, {
      key: "cell(timer)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.timer) + " ")];
      }
    }, {
      key: "cell(passing_grade)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.passing_grade) + " ")];
      }
    }, {
      key: "cell(sum_soal)",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.item.sum_soal) + " ")];
      }
    }]),
    model: {
      value: _vm.ujians,
      callback: function callback($$v) {
        _vm.ujians = $$v;
      },
      expression: "ujians"
    }
  })], 1)], 1), _c('b-tab', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('feather-icon', {
          staticClass: "mr-50",
          attrs: {
            "icon": "UsersIcon",
            "size": "18"
          }
        }), _c('span', {
          staticClass: "font-weight-bold"
        }, [_vm._v("Member")])];
      },
      proxy: true
    }])
  }, [_c('pilih-member', {
    attrs: {
      "jadwalujianDataId": _vm.jadwalujianDataId
    }
  })], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }